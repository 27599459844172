.login {
    input[type="text"],
    input[type="password"] {
        padding: 8px 16px;
        border: 6px solid $dark-grey;
        border-radius: 0;
        font-family: $font-heading;
        font-size: 22px;
        width: 100%;

        &::placeholder {
            color: $dark-grey;
        }

        @include small {
            font-size: 24px;
            padding: 10px 20px;
        }
    }

    &__menu {
        margin: 0;
        padding: 0;
        font-size: 26px;
        color: white;

        li {
            list-style: none;
            margin: 0 0 8px 0;
        }

        a {
            font-family: $font-heading;
            display: inline-block;
            margin-right: 10px;
            text-decoration: none;
            color: inherit;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
