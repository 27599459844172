body {
    font-family: $font-normal;
    font-size: 17px;
    line-height: 1.5rem;
    @include small {
        font-size: 16px;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-family: $font-heading;
}

a {
    text-decoration: none;
}

.tiny-text {
    font-size: 12px;
    line-height: 15px;
}

.text-error {
    color: $text-red;
}
