.profileData {

    margin-top: 30px;

    thead th {
        padding: 10px;
        font-weight: bold;
        text-transform: uppercase;
        color: white;
        background-color: $blue;
        border: 1px solid $blue;
    }

    tbody td {
        padding: 4px 10px;
        border: 1px solid $dark-grey;

        &:first-child {
            width: 200px;
            background-color: $light-grey;
            @include small {
                width: auto;
            }
        }
    }

    tfoot tr {
        background-color: $dark-grey;
    }

    tfoot td {
        padding: 10px;
    }

    .small-cell {
        width: 10px;
        white-space: nowrap;
    }
}
