html {
    height: 100%;
}

body {
    height: 100%;
    min-height: 100%;
    overflow-x: hidden;
    background-color: white;
}

#app {
    height: 100%;
}

.page-body {
    padding-top: 60px;
    padding-bottom: 100px;
}

.home-body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.bg-image {
    background-image: linear-gradient(#000000aa 10%, #ffffff00 100%), url("../images/mountain-view.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.header {
    padding-top: 20px;
    padding-bottom: 20px;
}

.header--public {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../images/mountain-view.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.header--home {
    background: none;
}

.logo {
    width: 405px;
    max-width: 100%;
    height: auto;
}

.main-menu {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;

    > li {
        list-style: none;
        margin: 12px 40px 12px 12px;
    }

    a {
        font-family: $font-heading;
        font-size: 22px;
        color: white;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
            color: white;
        }

        &:active,
        &:visited {
            color: white;
        }
        @include small {
            font-size: 18px;
        }
    }
}

.page-image {
    position: absolute;
    right: -66px;
    top: 66px;
    opacity: .5;
    width: 220px;
}

.metaData {
    font-size: 15px;
    margin-top: -8px;
    margin-bottom: 20px;
    color: $dark;
}

.table th, .table td {
    vertical-align: middle;
    padding: 0.1rem 0.5rem;
    font-size: 15px;
    @include small {
        font-size: 14px;
    }
}

div.dataTables_wrapper div.dataTables_info {
    padding-top: 0;
}

.form-control {
    box-shadow: inset 0 4px 0 $blue;
}

#profiles {
    tfoot {
        display: table-header-group;
    }
    a.notSubscribed {
        position: relative;
        &:before {
            content: "•";
            color: #ff0000;
            display: block;
            position: absolute;
            left: -7px;
            font-size: 22px;
            line-height: 18px;
        }
    }
}

.newsletter .profileData {
    margin: 0 !important;
}

.newsletter .newsletter-preview {
    height: 500px;
}

table.dataTable.stripe tbody > tr.odd.selected, table.dataTable.stripe tbody > tr.odd > .selected, table.dataTable.display tbody > tr.odd.selected, table.dataTable.display tbody > tr.odd > .selected, table.dataTable tbody > tr.selected, table.dataTable tbody > tr > .selected {
    background-color: #acbad4;
}

table.dataTable.display tbody > tr.odd.selected > .sorting_1, table.dataTable.order-column.stripe tbody > tr.odd.selected > .sorting_1, table.dataTable.display tbody > tr.even.selected > .sorting_1, table.dataTable.order-column.stripe tbody > tr.even.selected > .sorting_1 {
    background-color: #acbad4;
}

table.dataTable.hover tbody > tr.selected:hover, table.dataTable.hover tbody > tr > .selected:hover, table.dataTable.display tbody > tr.selected:hover, table.dataTable.display tbody > tr > .selected:hover {
    background-color: #acbad4;
}

table.dataTable tbody tr.selected a, table.dataTable tbody th.selected a, table.dataTable tbody td.selected a, table.dataTable .selected a, table.dataTable .selected a:hover {
    color: #0172d2;
}

table.dataTable thead th.select-checkbox {
    position: relative;
}

table.dataTable thead th.select-checkbox:before,
table.dataTable thead th.select-checkbox:after {
    display: block;
    position: absolute;
    top: 1.2em;
    left: 50%;
    width: 12px;
    height: 12px;
    box-sizing: border-box;
}

table.dataTable tbody td.select-checkbox:before,
table.dataTable thead th.select-checkbox:before {
    content: ' ';
    margin-top: -6px;
    margin-left: -6px;
    border: 1px solid black;
    border-radius: 3px;
}

table.dataTable > thead .sorting:before,
table.dataTable > thead .sorting:after,
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_asc:after,
table.dataTable > thead .sorting_desc:before,
table.dataTable > thead .sorting_desc:after,
table.dataTable > thead .sorting_asc_disabled:before,
table.dataTable > thead .sorting_asc_disabled:after,
table.dataTable > thead .sorting_desc_disabled:before,
table.dataTable > thead .sorting_desc_disabled:after {
    bottom: 0.5em;
}

.title-solid {
    background-color: $mid-blue;
    color: white;
    padding: 8px;
    margin-top: 1.4em;
    margin-bottom: 1em;
}

.modal {
    padding: 50px;
}

.modal-dialog {
    border: 5px solid $mid-blue;
    border-radius: 6px;
    background-color: $mid-blue;
}

.modal-content {
    border-radius: 20px;
    box-shadow: inset 0 0 10px 3px rgb(21 140 186 / 50%);
}

.loader {
    position: absolute;
    border: 16px solid #f8ecec; /* Light grey */
    border-top: 16px solid #158CBA;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    left: 40%;
    top: 35%;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.dataTables_wrapper .card {
    border: 0px;
    background-color: transparent;
}

footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 16px;
    background-color: #252525;
    color: white;
    font-size: 13px;
    z-index: 100;

    a, a:visited, a:link {
        color: white;
        text-decoration: underline;

        &:hover, &:active {
            color: #ff851b;
        }
    }
}

.alert {
    ul { margin: 0; }
    li { list-style: none; }
}
