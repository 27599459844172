// Body
$body-bg: #f8fafc;

// Typography
$font-heading: 'Gorditas', cursive;
$font-normal: 'Montserrat Alternates', sans-serif;

// Colors
$light-grey: #F8F8F8;
$dark-grey: #DEDEDE;
$dark: #777777;
$mid-blue: #158cba;
$text-red: #f50909;

// Bootstrap overrides
$modal-md: 900px;

@mixin small {
    @media (max-width: 768px) {
        @content;
    }
}
